




































































































































































import * as workerTimers from "worker-timers";

import { Component, Vue } from "vue-property-decorator";
import { dispatchGetMe, dispatchLogIn } from "@/store/auth/actions";
import { logOut, refreshToken } from "@/helpers/auth";

import LogoIcon from "@/components/icons/LogoIcon.vue";
import Message from "primevue/message";
import SkewLoader from "vue-spinner/src/SkewLoader.vue";
import axios from "axios";
import router from "@/router";
import { googleClientId } from "@/env";

@Component({
  components: {
    LogoIcon,
    SkewLoader,
    Message: Message,
  },
})
export default class Login extends Vue {
  public processing = false;
  public message = "";
  public loginFail = false;
  public toPath = "/";
  public authClient;

  public async beforeMount() {
    if (!this.$route.query.to || this.$route.query.to == "/login") {
      this.toPath = "/";
    } else {
      this.toPath = this.$route.query.to.toString();
    }
    const response = await dispatchGetMe(this.$store);

    if (response.status == 200) {
      return await this.$router.replace(this.toPath);
    } else if (response.status == 401) {
      const res = await refreshToken();
      if (res.status == 200) {
        return await this.$router.replace(this.toPath);
      }
    }
  }

  public mounted() {
    this.authClient = (window as any).google.accounts.oauth2.initCodeClient({
      client_id: googleClientId,
      scope: "profile email openid",
      callback: async (response) => {
        this.processing = true;
        this.loginFail = false;
        this.message = "Getting Google credentials";
        try {
          const authCode = response.code;
          this.message = "Logging in";
          await this.logIn(authCode);
        } catch (err) {
          this.loginFail = true;
          this.message = "Failed to complete Google login";
        }
        this.processing = false;
      },
    });
  }

  public handleAuth() {
    this.authClient.requestCode();
  }

  public async logIn(authCode: string) {
    try {
      await dispatchLogIn(this.$store, { authCode: authCode, to: this.toPath });
      this.message = "";
    } catch (e) {
      this.loginFail = true;
      this.message = e.message || "Login failed";
    }
  }
}
